@font-face {
    font-family: Udemy;
    src: url("../../../../Components/Fonts/Udemy-Sans-Regular-v1.woff2");
}
#root {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #1c1d1f;
}
body {
    margin: 0;
    padding: 0;
    background-color: #f8f9fb;
}
* {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1em;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}
.nav-link {
    padding: 0;
}
.dark-btn {
    color: white;
    background-color: #1c1d1f;
}
.dark-btn:hover {
    color: white !important;
    background-color: black;
}
.dark-btn a,
.dark-btn a:hover {
    color: white;
}
.light-btn {
    background-color: transparent;
    border: 1px solid #1c1d1f;
}
.light-btn a,
.light-btn a:hover {
    color: #1c1d1f;
}

.light-btn:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.cart-btn {
    color: white;
    background-color: #a435f0;
}
.cart-btn:hover {
    background-color: #8710d8;
}

.featured a {
    color: #5624d0;
    text-decoration: underline;
}
.featured a:hover {
    color: #401b9c;
    text-decoration: underline;
}
.featured span {
    color: #6a6f73;
}
footer {
    background: #1c1d1f;
    border-top: 1px solid #3e4143;
}
footer a {
    color: #fff !important;
}
footer ul li {
    padding: 0.1rem 0;
}
footer a:hover {
    color: #fff !important;
    text-decoration: underline !important;
}
.footer-btn {
    border: 1px solid #fff;
    color: #fff;
}

.btn-lang a {
    display: flex;
    height: 2.5rem;
    color: #1c1d1f !important;
    font-size: 1rem;
    justify-content: flex-start;
    padding-left: 1.6rem;
    align-items: center;
}
.btn-lang .active {
    border: 1px solid #1c1d1f !important;
}
.btn-lang a:hover {
    color: #401b9c !important;
}
.ant-modal-title {
    padding-bottom: 0;
    margin: 0 !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
    letter-spacing: -0.02rem !important;
    font-size: 1.2rem !important;
}

.ant-modal-header {
    border-bottom: none;
}

.ant-modal-content {
    padding-top: 20px;
}
