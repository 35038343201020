/* Add Button */
.btnAdd {
    z-index: 1;
    position: relative;
    font-size: inherit;
    font-family: inherit;
    color: white;
    padding: 0.5em 1em;
    outline: none;
    border: none;
    background-color: hsl(236, 32%, 26%);
    overflow: hidden;
    transition: color 0.4s ease-in-out;
}

.btnAdd::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: #a435f0;
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    transition: transform 0.45s ease-in-out;
}

.btnAdd:hover {
    cursor: pointer;
    color: #161616;
}

.btnAdd:hover::before {
    transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
}
.btnAction {
    background-color: white;
}
